import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { TopBarNotificationService } from './top-bar-notification.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleExclamation, faXmark } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'upskill-top-bar-notification',
    imports: [CommonModule, FontAwesomeModule],
    templateUrl: './top-bar-notification.component.html',
    styleUrls: ['./top-bar-notification.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(300, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                animate(300, style({ opacity: 0 }))
            ])
        ])
    ]
})
export class TopBarNotificationComponent {
  constructor(protected notification: TopBarNotificationService) {
  }

  protected readonly faXmark = faXmark;
  protected readonly faCircleExclamation = faCircleExclamation;
}
