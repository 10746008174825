import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TrpcService } from './services/trpc.service';
import { catchError, filter, from, fromEvent, merge, mergeMap, Observable, of, take, tap, timer } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import { InitialLoaderComponent } from './inital-loader/initial-loader.component';
import { ErrorOverlayComponent } from './error-overlay/error-overlay.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { StateService } from './services/state.service';
import { TopBarNotificationComponent } from './top-bar-notification/top-bar-notification.component';
import { TopBarNotificationService } from './top-bar-notification/top-bar-notification.service';
import { DateTime } from 'luxon';
import { InitialLoaderService } from './inital-loader/initial-loader.service';
import { ToastComponent } from './shared/toast/toast.component';
import { TranslocoService } from '@ngneat/transloco';
import { SsoService } from './services/sso.service';
import { CustomThemeService } from './services/custom-theme.service';
import { ErrorOverlayService } from './error-overlay/error-overlay.service';
import { LoadingOverlayService } from './loading-overlay/loading-overlay.service';
import { PreviewIndicatorComponent } from './preview-indicator/preview-indicator.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';

@Component({
    selector: 'app-root',
    imports: [CommonModule, RouterModule, InitialLoaderComponent, ErrorOverlayComponent, LoadingOverlayComponent, TopBarNotificationComponent, ToastComponent, PreviewIndicatorComponent, WorkInProgressComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements  OnInit {
  title = 'upskill';
  style: HTMLLinkElement | undefined;

  constructor(@Inject(DOCUMENT) private document: Document,
              private router: Router,
              private renderer2: Renderer2,
              private translocoService: TranslocoService,
              private initialLoaderService: InitialLoaderService,
              private themeService: CustomThemeService,
              private state: StateService,
              private errorOverlay: ErrorOverlayService,
              private overlay: LoadingOverlayService,
              private notification: TopBarNotificationService,
              private sso: SsoService,
              private trpc: TrpcService) { // import here so that constructor is called
    this.initialLoaderService.show();
    this.translocoService.setActiveLang('en');
  }

  get inMaintenance(): boolean {
    return (window as any).MAINTENANCE;
  }

  ngOnInit(): void {
    this.sso.authenticate()
      .pipe(
        tap(user => this.state.setState({ user })),
        mergeMap(() => this.loadLanguage()),
        mergeMap(() => this.applyTheme()),
        tap(() => this.startMaintenanceMonitor()),
        tap(() => this.initialLoaderService.hide()),
        catchError(async e => {
          this.state.setState({ user: null }); // We need to set user to null so that sign in page can be activated
          await this.router.navigate(['/sign-in']);
          return of(null);
        })
      )
      .subscribe();
  }

  private loadLanguage(): Observable<unknown> {
    return from(this.trpc.client.organization.get.query())
      .pipe(
        tap(organization => this.translocoService.setActiveLang(organization.language))
      );
  }

  private applyTheme() {
    if (!this.themeService.style) {
      return of(null);
    }

    this.style = this.renderer2.createElement('link') as HTMLLinkElement;
    this.renderer2.appendChild(this.document.head, this.style);
    this.renderer2.setProperty(this.style, 'rel', 'stylesheet');
    this.renderer2.setProperty(this.style, 'href', this.themeService.style);

    return merge(
      fromEvent(this.style, 'error')
        .pipe(
          tap(() => this.errorOverlay.show({ title: 'Failed to load custom style'}))
        ),
      fromEvent(this.style, 'load')
    )
      .pipe(
        take(1)
      );
  }

  private startMaintenanceMonitor(): void {
    timer(0, 30 * 60 * 1000) // every 30 min
      .pipe(
        // We start maintenance at 5AM UTC every day
        filter(() => {
          const nowHour = DateTime.utc().hour;
          return 4 <= nowHour && nowHour < 5;
        }),
        tap(() => this.notification.notify(this.translocoService.translate('maintenance.message')))
      )
      .subscribe();
  }
}
