<div class="modal-body alert-modal warn">
  <div>
    Leaving in the middle of question will be recorded as a failure.
  </div>
  <div>
    Are you sure you want to leave?
  </div>
  <div class="mt-3 d-flex" ngbautofocus>
    <div class="ms-auto d-flex">
      <button type="button" class="ms-auto btn btn-sm btn-outline-secondary me-1 prompt-btn" (click)="cancel()">No, Continue</button>
      <button type="button" class="btn btn-sm btn-warning prompt-btn" (click)="confirm()">Yes, Leave</button>
    </div>
  </div>
</div>
